<template>
  <div id="app">
    <Nav style="margin-bottom: 60px" :custom="account" />

    <Hero :custom="getSectionData('hero')" v-if="$router.currentRoute.name == 'home'"/>

  <router-view/>
  </div>
</template>

<script>
import data from "@/data/config.json";
import Nav from "@/components/Navigation.vue";
import Hero from "@/components/Hero.vue";
export default {
  name: "app",
  data() {
    return {
      data
    }
  },
  components: {
    Nav,
    Hero
  },
  created() {
      this.sections = this.data.sections;
  },
  methods: {
    getSectionData(type) {
      return this.sections.find(section => section.type === type) || {};
    }
  }
}
</script>
