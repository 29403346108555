<template>
  <div class="section_terms md:p-10" id="terms">
    <div class="md:w-3/4 md:px-4 md:pr-10">
      <h1 class="text-white font-bold">
        TERMS OF PARTICIPATION &amp; PRIVACY POLICY
      </h1>
      <p class="text-white mt-2">
        The Edgeryders online platform and activities are intended for people to
        cooperate within and across projects trying to build a better world. The
        word "better" has here a fairly broad range of meaning. These
        <a
          class="underline"
          href="https://edgeryders.eu/t/netiquette/45"
          target="_blank"
          >Community Guidelines</a
        >
        are here to help you understand what it means to be a member of
        Edgeryders. Don’t forget that your use of Edgeryders is subject to these
        Community Guidelines and our
        <a
          class="underline"
          href="https://edgeryders.eu/t/id/44"
          targert="_blank"
          >Terms of Service</a
        >.
      </p>
    </div>

    <div class="md:w-1/4 pt-6 md:p-0 md:px-6 border-gray-100 md:border-l">
      <h1 class="text-white font-bold">Contact Us</h1>
      <p class="text-white mt-2">
        <a class="underline" href="mailto:contact@edgeryders.eu" target="_blank"
          >Email</a
        ><br />
        <a
          class="underline"
          href="https://twitter.com/edgeryders"
          target="_blank"
          >Twitter</a
        ><br />
        <a
          class="underline"
          href="https://facebook.com/edgeryders"
          target="_blank"
          >Facebook</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>
