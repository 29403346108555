<template>
  <div class="section md:px-10 freelancers_container" id="browse">
    <div class="section_title md:center_title-md">
       <VueTagsInput
       class="w-full search_box"
                v-model="search"
                placeholder="Search for freelancers"
                add-on-key="[13,',',';','.']"
                :tags="search_tags"
                :add-only-from-autocomplete="true"
                :autocomplete-items="filteredSearch"
                @tags-changed="newSearch => search_tags = newSearch"
      >
      <div
    slot="autocomplete-item"
    slot-scope="props"
    class="my-item"
    :class="props.item.type"
    @click="props.performAdd(props.item)"
  >
    <span class="search_type" :class="props.item.type" 
    >
      {{ props.item.type }}
    </span>
    <span class="search_text">
    {{ props.item.text }}
  </span>
  </div>

  <div
    slot="tag-center"
    slot-scope="props"
    class="my-tag"
    :class="props.tag.type"
  >
  
  <span class="search_type" v-if="props.tag.type" :class="props.tag.type">
  </span>

  <span class="tag_text">
    {{ props.tag.text }}
  </span>
 
  </div>

  <div
    slot="tag-actions"
    class="tag-close"
    slot-scope="props"
    :class="props.tag.type"
    @click="props.performDelete(props.index)"
  >  
  </div>

    </VueTagsInput>
    </div>
   <masonry
  :cols="{default: 3, 1000: 3, 700: 1, 400: 1}"
  :gutter="30"
  >

  <div v-for="(entry, index) in filteredAirtable" :key="index">
        <transition
        name="custom-classes-transition"
    enter-active-class="animated tada"
    leave-active-class="animated bounceOutRight"
     tag="div"
     appear
     :key="index"
  >
<div class="profile border bg-white md:mb-2">
      <div class="flex user items-center border-b p-4">
        <div class="avatar mr-2" v-if="entry['fields']['Avatar']" :style="{backgroundImage: 'url(' + entry['fields']['Avatar'] + ')'}"></div>
        <div class="flex w-full">
          <div class="flex-col w-full">
            <div class="flex border-b w-full pb-1">
              <h3 class="text-xm font-bold">{{entry['fields']['First Name']}} <span v-if="entry['fields']['Last Name']">{{entry['fields']['Last Name']}}</span></h3>
            </div>
            <div class="meta">
              <span class="">{{ entry['fields']['Expertise'] }}</span> <span>· <span v-if="entry['fields']['City']">{{ entry['fields']['City'] }}, </span> <span v-if="entry['fields']['Country']">{{ entry['fields']['Country'] }}</span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 px-5">
        <p class="text-sm pr-6 description">{{entry['fields']['Profile']}}</p>
      </div>
      <div class="hide_section">
        <div class="px-5 pb-0" v-if="entry['fields']['Skills']">
          <h4 class="pb-2 border-b font-bold">Skills</h4>
          <div class="pt-3 description inline-block">
            <p v-for="skill in entry['fields']['Skills']" @click="setSkill(skill)" class="skill_tag" :key="skill">{{skill}}</p>
          </div>
        </div>
        <div class="px-5 pb-0 mb-0" v-if="entry['fields']['Languages']">
          <h4 class="pb-2 border-b font-bold">Languages</h4>
          <div class="pt-3 description inline-block pb-2">
            <p v-for="language in entry['fields']['Languages']" class="language_tag" :key="language" @click="setLanguage(language)">{{language}}</p>
          </div>
        </div>
        <div class="rate mx-5 mb-4 mt-0 inline-flex rounded-lg overflow-hidden border" v-if="entry['fields']['Rate']">
          <p class="p-2 px-3 font-light bg-gray-100 flex items-center border-r">Hourly Rate (estimate)</p>
          <p class="p-2 px-3 font-bold">€{{entry['fields']['Rate']}}</p>
        </div>
 

      <a class="footer" v-if="entry['fields']['Username']" :href="'https://edgeryders.eu/u/' + entry['fields']['Username']" >

        <div>Contact @{{entry['fields']['Username']}}</div>

      </a>
    </div>
    </div>
    </transition>

  </div>


    <!-- <div class="profile border bg-white md:mb-2" v-for="entry in filteredProfiles" :key="entry.user.username">
      <div class="flex user items-center border-b p-4">
        <div class="avatar mr-2" :style="{backgroundImage: 'url(' + entry.user.avatar_url + ')'}"></div>
        <div class="flex w-full">
          <div class="flex-col w-full">
            <div class="flex border-b w-full pb-1">
              <h3 class="text-xm font-bold">{{entry.data.account.name}} </h3>
              <span class="font-light ml-2">@{{entry.user.username}}</span>
            </div>
            <div class=" meta">
              <span class="font-light">{{ entry.data.profile.occupation }}</span> <span class="location">{{ entry.data.account.location.city }}, {{ entry.data.account.location.country }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 px-5">
        <p class="text-sm pr-6 description">{{entry.data.profile.description}}</p>
      </div>
      <div class="hide_section">
        <div class="px-5 pb-0">
          <h4 class="pb-2 border-b font-bold">Skills</h4>
          <div class="pt-3 description inline-block">
            <p v-for="skill in entry.data.profile.skills" class="mr-2 border rounded-lg p-1 px-2 mb-2 text-xs float-left" :key="skill">{{skill}}</p>
          </div>
        </div>
        <div class="px-5 pb-0 mb-0">
          <h4 class="pb-2 border-b font-bold">Languages</h4>
          <div class="pt-3 description inline-block pb-2">
            <p v-for="language in entry.data.profile.languages" class="mr-2 border rounded-lg p-1 px-2 mb-2 text-xs float-left" :key="language">{{language}}</p>
          </div>
        </div>
        <div class="rate mx-5 mb-4 mt-0 inline-flex rounded-lg overflow-hidden border">
          <p class="p-2 px-3 font-light bg-gray-100 flex items-center border-r">Hourly Rate (estimate)</p>
          <p class="p-2 px-3 font-bold">€{{entry.data.profile.rate.amount}}</p>
        </div>
 

      <div class="footer">

        <div>View Full Profile</div>
        <div>Contact @{{entry.user.username}}</div>

      </div>
    </div>
  </div> -->
  </masonry>

</div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/velocity/1.2.3/velocity.min.js"></script>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import axios from "axios";
import Velocity from 'velocity-animate'

export default {
  props: ["custom", "html", "airtable"],
  data() {
    return {
      search: "",
      profiles: "",
      account: null,
      avatars: [],
      suggestions: null,
      search_tags: [],
    }
  },
  created() {
    
    this.getSuggestions();
    var tabledata = this.airtable;
    var self = this;
    for(var i = 0; i < tabledata.length; i++){
    
        var username = tabledata[i]['fields']['Username'];

       axios.get("https://edgeryders.eu/u/" + username + ".json").then(function(response) {
        var avatar_template = "https://edgeryders.eu" + response.data.user.avatar_template.replace('{size}', 200);
        window.console.log(username);
        window.console.log(avatar_template);
        var obj = {};
        obj[username] = avatar_template;
        self.avatars.push(obj);
      })
    }
    window.console.log(this.avatars)
   },
  methods: {
     beforeEnter: function (el) {
     el.style.opacity = 0
      el.style.transform = 'scale(0)'
      el.style.transition = 'all 1s ease'
    },
    enter: function (el, done) {
     el.style.opacity = 1
      el.style.transform = 'scale(1)'
      el.style.transition = 'all 1s ease'
},
    leave: function (el, done) {
     el.style.opacity = 0
      el.style.transform = 'scale(0)'
      el.style.transition = 'all 1s ease'
    },
    setSkill(skill) {
     var obj = {
        "type": "skill",
        'text': skill,
        "classes": "skill"
      }
   
      this.search_tags.push(obj)
    },
    setLanguage(language) {
     var obj = {
        "type": "language",
        'text': language,
        "classes": "language"
      }
   
      this.search_tags.push(obj)
    },
    getArray(value) {
      return value.split(',');
    },
    getAvatar(username) {

    window.console.log(username)
     
    },
    getSuggestions() {
      var languages = [];
      var skills = [];
      var locations = [];
      var fields = [];



      this.airtable.map(function(entry) {


          entry.fields.Skills.map(function(skill) {
            var obj = {
              "type": "skill",
              'text': skill,
              "classes": "skill"
            }
            skills.push(obj)
         })

         entry.fields.Languages.map(function(language) {
            var obj = {
              "type": "language",
              'text': language,
              "classes": "language"
            }
           languages.push(obj)
         })

     
          var city = entry.fields.City;
          if (city && !locations.includes(city)) {
            var city_obj = {
              "type": "location",
              "text": city,
              "classes": "location"
            }
            var cityindex = locations.findIndex(c => c.text==city)

            if (cityindex === -1){
              locations.push(city_obj);
            }
          } 
          var country = entry.fields.Country;
          if (country && !locations.includes(country)) {
            var country_obj = {
              "type": "location",
              "text": country,
              "classes": "location"
            }
            var countryindex = locations.findIndex(d => d.text==country)

            if (countryindex === -1){
              locations.push(country_obj);
            }
          }
          var field = entry.fields.Expertise;
          if (field && !fields.includes(field)) {
            var field_obj = {
              "type": "field",
              "text": field,
              "classes": "field"
            }
            var fieldindex = fields.findIndex(e => e.text==field)

            if (fieldindex === -1){
              fields.push(field_obj);
            }
          } 
      });
      this.suggestions = languages.concat(skills, locations, fields);
    }
  },
  components: {
    VueTagsInput
  },
  computed: {
    filteredSearch() {
      if (this.search == ' ') {
        return this.suggestions;
      } else {
        return this.suggestions.filter(i => {
          return i.text.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
        });
      }
    },
    filteredAirtable() {
      var self = this;
      var filtered = this.airtable;

      for(var i = 0; i < this.search_tags.length; i++){
         if (self.search_tags[i].type == 'language') {
            filtered = filtered.filter(x => {
              return x['fields']['Languages'].includes(self.search_tags[i].text);
            });
         } 
         if (self.search_tags[i].type == 'skill') {
            filtered = filtered.filter(x => {
              return x['fields']['Skills'].includes(self.search_tags[i].text);
            });
         } 
         if (self.search_tags[i].type == 'location') {
            filtered = filtered.filter(x => {
              return x['fields']['City'] == self.search_tags[i].text || x['fields']['Country'] == self.search_tags[i].text;
            });
         }
         if (self.search_tags[i].type == 'field') {
            filtered = filtered.filter(x => {
              return x['fields']['Expertise'] == self.search_tags[i].text;
            });
         } 
      }
      return filtered
    },
    filteredProfiles() {
      var self = this;
      var filtered = this.custom;
      for(var i = 0; i < this.search_tags.length; i++){
         if (self.search_tags[i].type == 'language') {
            filtered = filtered.filter(x => {
              return x.data.profile.languages.includes(self.search_tags[i].text);
            });
         } 
         if (self.search_tags[i].type == 'skill') {
            filtered = filtered.filter(x => {
              return x.data.profile.skills.includes(self.search_tags[i].text);
            });
         } 
         if (self.search_tags[i].type == 'location') {
            filtered = filtered.filter(x => {
              return x.data.account.location.city == self.search_tags[i].text || x.data.account.location.country == self.search_tags[i].text;
            });
         }
         if (self.search_tags[i].type == 'field') {
            filtered = filtered.filter(x => {
              return x.data.profile.occupation == self.search_tags[i].text;
            });
         } 
      }
      return filtered;
    }
  }
};
</script>
<style lang="scss">
.language_tag, .skill_tag {
  @apply mr-2 border rounded-sm p-1 px-2 mb-2 text-xs float-left;
  &:hover {
  cursor: pointer;
  color: white;
}
}
.skill_tag:hover {
  background: #5C6BC1;
}
.language_tag:hover {
  background: #FE6F40;
}
.search_box {
  border: none;
  .ti-input {
  border: none !important;
}
.ti-new-tag-input {
  font-size: 24px;
}
}
.columns {
  column-count: 3;
}
.profile {
  transition: all 1s ease;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 15px;
  width: 100%;
  display: inline-block;
}
.hide_section {
  max-height: 0;
  opacity: 0;
  transition: max-height .5s ease, opacity .3s ease;
}
.profile:hover {
  .hide_section {
    max-height: 900px;
    opacity: 1;
  }
}
.user {
  background: linear-gradient(180deg,hsla(0,0%,92.9%,.6),#fff);
  @apply w-full;
  .avatar {
    flex-shrink: 0;
    width: 55px;
    margin-right: 15px;
    height: 55px;
    background-size: cover;
    border-radius: 5px;
  }
}
.description {
  font-size: 14px;
}
.rate {
  font-size: 12px;
}

.meta {
font-size: 14px;
padding-top: 7px;
}

.footer {
  @apply flex w-full bg-gray-100 border-t;
  div {
    @apply w-full p-3 text-center font-light;
    font-size: 14px;
    &:first-child {
    @apply border-r;
  }
  }
}
.my-item {
  @apply inline-flex overflow-hidden;
    border-radius: 3px;
    border: none;
    padding: 0 !important;
    height: 30px;
  width: auto !important;
  font-size: 16px !important;
  margin: 0 5px 0 !important;
  span {

    @apply flex items-center;

}
}
.search_type {
  @apply border-r;
  background: #efefef;
    padding: 1px 10px 0;
  font-size: 9px;
  font-weight: 100;
  color: white;
  text-transform: uppercase;
  &.field {
   background: url("data:image/svg+xml,%3Csvg width='48' height='69' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='.4' d='M48 24a24 24 0 01-3.5 12.4l-.2.3c-.8 1.2-1.6 2.4-2.6 3.4-3.5 4.9-5.3 9.1-5.2 11.8v.1l.1.8v8.6c0 3.8-3.1 6.8-6.8 6.8H18.7a6.8 6.8 0 01-6.8-6.8v-8.6-.5-.4c.1-2.6-1.7-6.7-5-11.5a20 20 0 01-3-4l-.3-.5A23.9 23.9 0 1148 24zM32.9 53.8H15.6v3.4h17.3v-3.4zm0 7.8v-.7H15.6v.7a3.1 3.1 0 003.1 2.8h11.1c1.6 0 2.9-1.2 3.1-2.8zM44.3 24A20.1 20.1 0 106.9 34.2v.1c.8 1.3 1.7 2.5 2.7 3.6l.2.2c3.3 4.7 5.2 8.7 5.7 12H33c.6-4.1 3.4-8.8 5.9-12.3l.2-.2c.9-.9 1.7-2 2.3-3.1l.1-.2.1-.2c1.8-3 2.7-6.5 2.7-10.1z' fill='white' fill-rule='nonzero'/%3E%3C/svg%3E") no-repeat 16% 48% #009D6A;
  background-size: 8px !important;
  padding-left: 23px !important;
}
&.skill {
  background: url("data:image/svg+xml,%3Csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M8.8 3.8a.5.5 0 01.6.4.5.5 0 01-.4.6h-.6V5c0 .4 0 .9-.2 1.3l.5.3a.5.5 0 11-.5.8l-.5-.2-1.1.9.2.5a.5.5 0 11-1 .3l-.1-.5H4.2L4 9a.5.5 0 11-.9-.3l.2-.5-1.1-1-.5.3-.2.1a.5.5 0 01-.3-1l.5-.2c-.2-.4-.3-.9-.3-1.3v-.1L1 4.8a.5.5 0 01.2-1l.5.1c.2-.4.4-.9.7-1.2L2 2.3a.5.5 0 01.8-.7L3 2c.4-.2.9-.4 1.3-.4V1a.5.5 0 111 0v.6c.5 0 1 .2 1.4.4l.3-.4a.5.5 0 01.8.7l-.4.4c.4.3.6.8.7 1.2h.6zM4.9 7.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z' fill-rule='nonzero'/%3E%3C/svg%3E") no-repeat 13.5% 48% #5C6BC1;
  background-size: 11.5px !important;
  padding-left: 23px !important;
}
&.language {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 187.5'%3E%3Cstyle%3E.st1%7Bfill:none;stroke:%23fff;stroke-width:5;stroke-linecap:round;stroke-linejoin:round%7D%3C/style%3E%3Cpath fill='none' d='M0 0h150v150H0z'/%3E%3Ccircle class='st1' cx='75' cy='75' r='50'/%3E%3Cellipse class='st1' cx='75' cy='75' rx='18.1' ry='50'/%3E%3Cpath class='st1' d='M28 59h94M28 91h94'/%3E%3C/svg%3E") no-repeat 7.5% 73% #FE6F40;
  background-size: 16.5px !important;
  padding-left: 25px !important;
}
&.location {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 30'%3E%3Cpath fill='rgba(0,0,0,0)' stroke='white' stroke-width='1.2px' d='M18.4 6a7 7 0 00-5.7-4 8.9 8.9 0 00-1.4 0 7 7 0 00-5.7 4 7.5 7.5 0 00.4 7.4l5.2 8.1a1 1 0 001.6 0l5.2-8.1a7.5 7.5 0 00.4-7.4zM12 12a3 3 0 113-3 3 3 0 01-3 3z'/%3E%3C/svg%3E") no-repeat 9% 65% #0286EC;
  background-size: 14px !important;
  padding-left: 24px !important;
}
}
.tag_text {
  font-size: 12px;
  padding: 0 0 0 10px;
}
.search_text {
font-size: 12px;
  padding: 0 10px 0 10px;
  background: #fafafa;
}
@keyframes slideup {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.ti-autocomplete {
  @apply flex items-center pb-3 px-2 bg-white shadow-lg rounded-lg;
  animation: slideup .3s ease forwards;
  border: none !important;
  ul {
  @apply inline-block items-center;
  padding: 0  !important;
  margin: 0 !important;
  margin-block-start: 0 !important;
  padding-inline-start: 0 !important;
  margin-block-end: 0 !important;
}
  .ti-item {
  width: auto !important;
  float: left;
  color: black !important;
  padding: 0 0 0px !important;
   margin-block-start: 0 !important;
  padding-inline-start: 0 !important;
  margin-block-end: 0 !important;

}
.ti-selected-item {
  background: none !important;
  .my-item {
  .search_text {
    background: #ddd;
  }
  }
}
}
.ti-actions {
  width: 25px;
    display: block !important;
    margin: 0!important;
    .tag-close {
  background: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M23 19a3 3 0 01-4 4l-7-7-7 7a3 3 0 01-4-4l7-7-7-7a3 3 0 014-4l7 7 7-7a3 3 0 014 4l-7 7 7 7z' fill-rule='nonzero'/%3E%3C/svg%3E") 45% center no-repeat;
  height: 100% !important;
  width: 100% !important;
  background-size: 9px;
}
}

.ti-tag {
  padding: 0 !important;
  margin-right: 10px !important;
   &.field {
    background-color: #009D6A !important;
    }
    &.skill {
    }
    &.language {
      background-color: #FE6F40 !important;
    }
    &.location {
      background-color: #0286EC !important;
    }
  .my-tag {
    height: 100%;
    @apply flex items-center;
  
    .search_type {
      height: 34px;
      padding: 0 !important;
      width: 34px !important;
      border-right: .5px solid rgba(255,255,255,0.3);
      &.field {
      background: url("data:image/svg+xml,%3Csvg width='48' height='69' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='.4' fill='white' d='M48 24a24 24 0 01-3.5 12.4l-.2.3c-.8 1.2-1.6 2.4-2.6 3.4-3.5 4.9-5.3 9.1-5.2 11.8v.1l.1.8v8.6c0 3.8-3.1 6.8-6.8 6.8H18.7a6.8 6.8 0 01-6.8-6.8v-8.6-.5-.4c.1-2.6-1.7-6.7-5-11.5a20 20 0 01-3-4l-.3-.5A23.9 23.9 0 1148 24zM32.9 53.8H15.6v3.4h17.3v-3.4zm0 7.8v-.7H15.6v.7a3.1 3.1 0 003.1 2.8h11.1c1.6 0 2.9-1.2 3.1-2.8zM44.3 24A20.1 20.1 0 106.9 34.2v.1c.8 1.3 1.7 2.5 2.7 3.6l.2.2c3.3 4.7 5.2 8.7 5.7 12H33c.6-4.1 3.4-8.8 5.9-12.3l.2-.2c.9-.9 1.7-2 2.3-3.1l.1-.2.1-.2c1.8-3 2.7-6.5 2.7-10.1z' fill-rule='nonzero'/%3E%3C/svg%3E") no-repeat 50% 50% rgba(255,255,255,0.1);
        background-size: 10.2px !important;
    }
      &.skill {
        background: url("data:image/svg+xml,%3Csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M8.8 3.8a.5.5 0 01.6.4.5.5 0 01-.4.6h-.6V5c0 .4 0 .9-.2 1.3l.5.3a.5.5 0 11-.5.8l-.5-.2-1.1.9.2.5a.5.5 0 11-1 .3l-.1-.5H4.2L4 9a.5.5 0 11-.9-.3l.2-.5-1.1-1-.5.3-.2.1a.5.5 0 01-.3-1l.5-.2c-.2-.4-.3-.9-.3-1.3v-.1L1 4.8a.5.5 0 01.2-1l.5.1c.2-.4.4-.9.7-1.2L2 2.3a.5.5 0 01.8-.7L3 2c.4-.2.9-.4 1.3-.4V1a.5.5 0 111 0v.6c.5 0 1 .2 1.4.4l.3-.4a.5.5 0 01.8.7l-.4.4c.4.3.6.8.7 1.2h.6zM4.9 7.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z' fill-rule='nonzero'/%3E%3C/svg%3E") no-repeat 50% 50% rgba(255,255,255,0.1);
        background-size: 14.5px !important;
      }
      &.language {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 150 187.5'%3E%3Cstyle%3E.st1%7Bfill:none;stroke:%23fff;stroke-width:6;stroke-linecap:round;stroke-linejoin:round%7D%3C/style%3E%3Cpath fill='none' d='M0 0h150v150H0z'/%3E%3Ccircle class='st1' cx='75' cy='75' r='50'/%3E%3Cellipse class='st1' cx='75' cy='75' rx='18.1' ry='50'/%3E%3Cpath class='st1' d='M28 59h94M28 91h94'/%3E%3C/svg%3E") no-repeat 50% 65% rgba(255,255,255,0.1);
        background-size: 17px !important;
      }
      &.location {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 30'%3E%3Cpath fill='rgba(0,0,0,0)' stroke='white' stroke-width='1.2px' d='M18.4 6a7 7 0 00-5.7-4 8.9 8.9 0 00-1.4 0 7 7 0 00-5.7 4 7.5 7.5 0 00.4 7.4l5.2 8.1a1 1 0 001.6 0l5.2-8.1a7.5 7.5 0 00.4-7.4zM12 12a3 3 0 113-3 3 3 0 01-3 3z'/%3E%3C/svg%3E") no-repeat 50% 65% rgba(255,255,255,0.1);
        background-size: 16px !important;
        padding-left: 28px !important;
      }
    }

}
}
.freelancers_container {
  @apply inline-block;
  min-height: 400px;
  padding-bottom: 100px !important;
}
@keyframes scale {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  } 
}
.custom-appear-class {
  animation: scale 1s ease forwards;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.animated.tada {
  animation: scale .65s ease forwards;

}
.animated.bounceOutRight {

}
</style>
