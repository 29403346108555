<template>
  <div class="flex flex-col w-full">
    <Freelancers v-if="load" :custom="DummyData" :airtable="airtable" />
    <Terms />
  </div>
</template>

<script>
import data from "@/data/config.json";

import Freelancers from "@/components/Freelancers.vue";
import Terms from "@/components/Terms.vue";

import axios from "axios";

export default {
  name: "home",
  data() {
    return {
      data,
      current: null,
      load: false,
      airtable: false,
      category: { users: [] },
      categories: [],
      sections: null,
      account: null,
      profiles: []
    };
  },
  components: {
    Freelancers,
    Terms
  },
  created() {
    var self = this;
  

    axios.get(
      `https://edgeryders.eu/webkit_components/topics.json?serializer=event&tags=er-freelancer`
    ).then(({ data }) => {
      this.profiles = data.map(post => this.getProfile(post));
    });

    this.sections = this.data.sections;


      let axiosConfig = {
        headers: {
        'Authorization': 'Bearer keyIy7UbuTaEs4AWi',
        'Content-Type': 'application/json'
        }
      };

      

      axios.get('https://api.airtable.com/v0/appKoTSdhX1dwty8m/Contractors_Site', axiosConfig)
      .then(function (response) {
      // handle success
      window.console.log(response)
      var airtabledata = response.data.records;
      var mapped = airtabledata.map(function(entry) {
        if (entry['fields']['Skills']) {
        var skills = self.getArray(entry['fields']['Skills']);
        }
        if (entry['fields']['Languages']) {
        var languages = self.getArray(entry['fields']['Languages']);
        }
        if (entry['fields']['Certificates']) {
        var certificates = self.getArray(entry['fields']['Certificates']);
        }

        entry['fields']['Skills'] = skills;
        entry['fields']['Languages'] = languages;
        entry['fields']['Certificates'] = certificates;




        return entry;
      })
      self.airtable = mapped;
      self.load = true;

      })
      .catch(function (error) {
      // handle error
        window.console.log(error);
      })
      .finally(function () {
      // always executed
      });


  },
  methods: {
    getArray(value) {
      return value.split(',');
    },
    getAvatars() {
      var self = this;
     for(var i = 0; i < self.airtable.length; i++){
      if (self.airtable[i] && self.airtable[i]['fields']) {
      var username = self.airtable[i]['fields']['Username'];
        axios.get("https://edgeryders.eu/u/" + username + ".json").then(function(response) {
          var avatar = "https://edgeryders.eu" + response.data.user.avatar_template.replace('{size}', 200);
              self.airtable[i]['fields']['Username'] = avatar;
              window.console.log(avatar)

      });
      }
    }
  },
    getSectionData(type) {
      return this.sections.find(section => section.type === type) || {};
    },
    getProfile(post) {
      var obj = {
        user: '',
        data: ''
      }
      obj.user = post.author;
      obj.data = this.getJson(post.cooked);
      return obj
    }
  }
};
</script>
