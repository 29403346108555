<template>
  <header>
    <div class="menu pr-6 md:pr-10">
      <span class="nav-item logo"></span>
      <div class="flex h-full">


          <router-link to="/" class="nav-item mr-4" :class="{active: isProjectTypeRoute('home')}">
          Freelancers
          </router-link>

          <router-link to="/join" class="nav-item" :class="{active: isProjectTypeRoute('join')}">
          Join
          </router-link>

     
      <div v-if="custom" class="flex items-center pb-2">
        <div class="profile_badge mt-1" style="font-size: 14px">
          <div class="avatar" :style="{ backgroundImage: 'url(' + custom.avatar + ')' }"></div>
          <p>@{{ custom.username }}</p>
        </div>
      </div>
    </div>
    </div>
  </header>
</template>

<script>

import CryptoJS from "crypto-js";

export default {
  name: "Navigation",
  props: ["data", "custom"],
  data() {
    return {
      loginurl: null,
      active: "home"
    }
  },
  created() {
    var payload = "nonce=1234&return_sso_url=http://localhost:8080";
    let base = btoa(payload);
    let res = encodeURI(base);
    let encrypted = CryptoJS.HmacSHA256(base, 'kwp49nekuqhr29nsv3jnskxgjophg28');
    this.loginurl = "https://communities.edgeryders.eu/session/sso_provider?sso=" + res + '&sig=' + encrypted;
  },
  methods: {
    isProjectTypeRoute(name) { 
      return this.$router.currentRoute.name == name
    }
  }
};
</script>

<style lang="scss" scoped>
.menu {
  background-color: #fff;
  display: flex;
  position: fixed;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
  z-index: 11;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.nav {
  @apply font-display font-bold;
  white-space: nowrap;
  overflow-x: scroll;
  width: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
}

.profile_badge {
  @apply inline-flex items-center font-bold border border-gray-200 w-auto p-1 px-1 pr-3 rounded-lg;
  align-self: flex-start;
  flex-shrink: 0;
  .avatar {
    @apply inline-block mr-2;
    background-size: cover;
    background-position: center center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    border-radius: 6px;
  }
}

.nav-item {
  height: 40px;
  @apply font-bold bg-gray-200 rounded-lg;
  display: inline-flex;
  cursor: pointer;
  padding: 14px 14px 14px;
  text-transform: capitalize;
  align-items: center;
  color: black !important;
  &:last-child {
    border-right: none !important;
  }
  &:hover {
  @apply bg-gray-300
}
  &.router-link-exact-active {
    color: #fff !important;
    @apply bg-black;
    color: white;
    background-color: black;
  }
  &:not(:last-child) {
    border-right: 0.5px solid rgba(255, 255, 255, 0.2);
  }
}

.logo {
  min-width: 65px;
  height: 60px;
  padding: 0 50px 0 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.28);
  background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' version='1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='orangered' d='M0 250a250 250 0 1 1 500 0 250 250 0 0 1-500 0zm250 183V250H67a183 183 0 1 1 183 183z' fill-rule='evenodd'/%3E%3C/svg%3E")
    no-repeat center #fafafa;
  background-size: 45%;
  transition: none;
  box-shadow: none;
  left: 0;
  z-index: 99999;
}
</style>
